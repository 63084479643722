html {
  background-color: #ffffff;
  box-sizing: border-box;
}

body {
  font-family: "Roboto", sans-serif;
  background-color: #252C3C;
  background-image: url(../image/grainy.webp);
  background-size: cover;
  font-weight: 300;
}

*,
*:before,
*:after {
  box-sizing: inherit;
  padding: 0;
  margin: 0;
}

.container {
  width: 100%;
  margin-right: auto;
  margin-left: auto;
}

@media (min-width: 576px) {
  .container {
    max-width: 540px;
  }
}
@media (min-width: 768px) {
  .container {
    max-width: 720px;
  }
}
@media (min-width: 992px) {
  .container {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .container {
    max-width: 1200px;
  }
}
@media (min-width: 1400px) {
  .container {
    max-width: 1200px;
  }
}

h1 {
font-family: 'Rosario';
font-style: normal;
font-weight: 700;
font-size: 18px;
line-height: 60px;
text-align: center;
letter-spacing: 0.1em;
text-transform: uppercase;
color: #92A6B9;
padding-bottom: 25px;
}

h2 {
font-family: 'Saira';
font-style: normal;
font-weight: 400 !important;
font-size: 40px;
line-height: 60px;
color: #FFFFFF;
}

h3 {
  font-family: 'Saira';
font-style: normal;
font-weight: 400;
font-size: 25px;
line-height: 60px;
text-align: center;
text-transform: uppercase;
color: #FFFFFF;
}

p ,
.slide-down-measure span{
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 17px;
line-height: 35px;
color: #FAFBFB;
z-index: 2;
position: relative;
}

p:not(:last-child) {
  padding-bottom: 30px;
}

@media only screen and (max-width: 768px) {
  h1 {
    font-size: 32px;
    line-height: 38px;
    margin-bottom: 24px;
  }
}

h1 .thin {
  font-weight: 300;
}

h2 {
  font-style: normal;
  font-weight: 900;
  font-size: 42px;
  line-height: 62px;
  margin-bottom: 25px;
}

@media only screen and (max-width: 768px) {
  h2 {
    font-size: 28px;
    line-height: 36px;
  }
}

p {
  font-size: 16px;
  line-height: 30px;
}

ul {
  list-style: none;
}

a {
  text-decoration: none;
}



/* Banner */
.banner{
  background: #FFFFFF;
  box-shadow: 0px 4px 105px rgba(34, 48, 74, 0.2);
  border-radius: 0px 0px 10px 10px;
  padding: 10px 20px 10px 10px;
  display: grid;
  gap: 30px;
  align-items: center; 
  grid-template-columns: 0.8fr 2fr 0.7fr;
  margin-bottom: 25px;
  position: relative;
  z-index: 10;
}
.banner .logo-box{
  background: #EAF2F8;
  border-radius: 10px;
}
.banner .logo-box a{
  background: url(../image/domainer_logo.svg);
  background-repeat: no-repeat;
  background-position: center center;
  color: transparent;
  display: block;
  font-size: 0;
  padding: 20px 30px;
  height: 138px;
}
.banner .banner-title{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 700;
  font-size: 26px;
  line-height: 30px;
  margin-bottom: 15px;
  color: #090909;
}
.banner .info{
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 24px;
  color: #7D7F82;
  position: relative;
  margin-left: 15px;
  background-color: transparent;
  padding: 0;
  text-align: left;
}
.banner .info:before{
  content: "";
  position: absolute;
  left: 0;
  bottom: 0;
  width: 6px;
  height: 100%;
  margin-left: -15px;
  border-radius: 10px;
  background: #F27558;
}
.banner .domaner-btn{
  background: #F27558;
  transition: all 0.5s ease-in-out;
  border-radius: 10px;
  padding: 23px 5px;
  display: block;
  font-family: 'Montserrat';
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 22px;
  text-align: center;
  color: #000000;
}
.info a {
  
  text-decoration: underline;
}
.banner .domaner-btn:hover {
  background-color: rgb(249, 173, 141);
}
@media only screen and (max-width: 1090px) {
  .banner .banner-title{
      font-size: 22px;
      margin-bottom: 10px;
  }
  .banner .info{
      font-size: 18px;
      text-align: left!important;
  }
  .banner .domaner-btn{
      font-size: 18px;
  }
  .banner .domaner-btn {
      font-size: 18px;
      line-height: 22px;
      display: table;
      margin: 0 auto;
      padding: 23px 30px;
  }
}
@media only screen and (max-width: 940px) {
  .banner{
      grid-template-columns: 1fr;
      padding: 10px;
  }
  .banner .logo-box a{
      background: url(../image/domainer_logo_mob.svg);
      background-repeat: no-repeat;
      background-position: center center;
      height: 66px;
  }
  .banner .banner-title{
      line-height: 24px;
      text-align: center;
  }
  .banner .info{
      font-size: 16px;
      line-height: 20px;
      text-align: center!important;
  }
  .banner .info:before{
      content: none;
  }
}
@media only screen and (max-width: 440px) {
  .banner .domaner-btn {
      display: block;
      padding: 23px 0px;
  }
}

h1 span {
  display: none;
}

.site-logo {
  font-weight: bold;
  font-size: 48px;
  color: transparent;
  background-image:url(../image/iMetaverse.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
    display: flex;
    justify-content: center;
    position: relative;
  
}

.site-header .site-logo::before {
  content: "";
  position: absolute;
  width: 215px;
  height: 218px;
  background: #5D86FF;
  opacity: 0.3;
  filter: blur(40px);
  left: -32px;
    z-index: -1;
    top: -50px;
    border-radius: 50%;
}

.site-header .site-logo::after {
  content: "";
  position: absolute;
  width: 63px;
  height: 64px;
  background: #D75FD2;
  opacity: 0.3;
  border-radius: 50%;
  left: -25px;
    bottom: -95px;
    filter: blur(18px);
}

.logo-wrapper {
  padding-top: 25px;
  padding-bottom: 22px;
}
.site-header {
  color: #fff;
}
.site-header-inner {
  padding: 0 25px;
  display: grid;
    grid-template-columns: 1fr 35%;
    position: relative;
    z-index: 2;
    align-items: center;
  
}
.site-header-inner::before {
  content: "";
  position: absolute;
  width: 100%;
  height: 809px;
  background: #9AAAD9;
  opacity: 0.3;
  filter: blur(200px);
  top: -591px;
    border-radius: 50%;
    z-index: -1;
}

.site-header p {
  text-align: center;
}

.header-description {
  padding: 0 25px;
}
.header-content {
  padding: 100px 25px;
  position: relative;
}

.header-content::after {
  position: absolute;
  content: "";
  background-image: url(../image/border-hero.png);
  height: 100%;
  width: 2px;
  background-size: contain;
  background-repeat: no-repeat;
  top: 0;
  right: 0;
}

.header-nav {
  padding: 100px 60px;
}

.header-nav ul li a {
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 34px;
  text-transform: uppercase;
  color: #E2F1FF;
  padding: 10px 0;
  position: relative;
  transition: all 0.2s ease-in-out;
}
.header-nav ul li+li{
  margin-top: 70px;
}
.header-nav ul li a::before {
  position: absolute;
  content: "";
  width: 30px;
  height: 2px;
  background-color: #2C8CE4;
  bottom: 0;
  left: 0;
}

.header-nav ul li a:hover {
  color: #2c8ce4;
}

.header-description .m-slide-down{
  height: auto;
}
.header-description .m-readmore-btn-wrapper{
  display: none;
}

.grid-box {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  gap: 30px;
  position: relative;
}

.grid-box::before {
  position: absolute;
  content: "";
  background-image: url(../image/box-grid-border.png);
  top: 0;
  background-repeat: no-repeat;
  height: 1px;
  width: 100%;
  right: 0;
}

.grid-box::after {
  position: absolute;
  content: "";
  background-image: url(../image/box-grid-border.png);
  bottom: 0;
  background-repeat: no-repeat;
  height: 1px;
  width: 100%;
  right: 0;
}

.grid-box h3 {
  font-family: 'Roboto';
font-style: normal;
font-weight: 500;
font-size: 20px;
line-height: 40px;
text-align: center;
text-transform: uppercase;
color: #E2F1FF;
transition: all 0.3s ease-in-out;
}

.grid-box p {
  font-family: 'Roboto';
font-style: normal;
font-weight: 300;
font-size: 16px;
line-height: 20px;
text-align: center;
color: #C6D1DB;
}

.why-care,
.about-us {
  padding: 0 25px 40px;
  background-image: url(../image/grid-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  transition: all 0.3s ease-in-out;
  position: relative;
  height: 100%;
}

.watch-how {
  position: relative;
  overflow: hidden;
  z-index: 2;
  padding: 0 25px 40px;
  transition: all 0.3s ease-in-out;
  height: 100%;
}

.watch-how::before {
  content: "";
  position: absolute;
  background-image: url(../image/watch-how-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 100%;
  left: 0;
  z-index: -1;
}

.why-care:hover .box-img.why,
.watch-how:hover .box-img.watch,
.about-us:hover .box-img.about {
  filter: saturate(1);
}

.why-care:hover h3,
.watch-how:hover h3,
.about-us:hover h3 {
color: #2C8CE4;
transition: all 0.3s ease-in-out;
}

.why-care {
  border-bottom: 2px solid #2c8be4d3;
  position: relative;
  z-index: 2;
}

.why-care::before {
  position: absolute;
  content: "";
  background-image: url(../image/care-flash.png);
  width: 240px;
  height: 175px;
  bottom: -64px;
  right: -70px;
}

.watch-how::after {
  position: absolute;
  content: "";
  background-image: url(../image/watch-border-bottom.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
}

.about-us::before {
  position: absolute;
  content: "";
  background-image: url(../image/about-border-bottom.png);
  background-size: cover;
  background-repeat: no-repeat;
  width: 100%;
  height: 2px;
  bottom: 0;
  left: 0;
}


.box-img.why {
  background-image: url(../image/vr-glasses-hover.webp);
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

.box-img.watch {
  background-image: url(../image/play-btn-hover.webp);
  background-size: 130px;
  background-repeat: no-repeat;
  background-position: center;
}

.box-img.about {
  background-image: url(../image/futuristic-hover.webp);
  background-size: 200px;
  background-repeat: no-repeat;
  background-position: center;
}

.box-img {
  width: 100%;
  height: 250px;
  filter: saturate(0.08);
}

.what-is {
  padding: 100px 0;
  text-align: center;
  position: relative;
}

.what-is::before, .faq-wrapper:after, .what-is-the-future:after{
  position: absolute;
  content: "";
  width: 122px;
  height: 123px;
  background: #5D86FF;
  opacity: 0.3;
  filter: blur(25px);
  right: 0;
  top: 50%;
  border-radius: 50%;
}

.what-is::after {
  position: absolute;
  content: "";
  width: 59px;
  height: 57px;
  background: #D75FD2;
  opacity: 0.3;
  filter: blur(20px);
  border-radius: 50%;
  right: 42px;
  bottom: 20%;
}

.grid1 {
  display: flex;
  padding: 25px 50px 50px 110px;
  flex-wrap: wrap;
  justify-content: space-between;
}

.grid1 li {
  font-family: 'Roboto';
font-style: normal;
font-weight: 700;
font-size: 20px;
line-height: 45px;
color: #FAFBFB;
position: relative;
}

.grid1 li::before {
  position: absolute;
  content: "";
  width: 6px;
  height: 6px;
  border-radius: 50%;
  background: #2C8CE4;
  left: -20px;
  top: calc(50% - 2px);
}

.how-it-works h2,
.how-it-works p {
  text-align: center;
}

.yt-video {
  padding: 45px;
  background-image: url(../image/youtube-bg.png);
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
  margin: 50px 0;
  background-color: #273045;
  max-height: 100%;
}

.yt-video::before {
  position: absolute;
  content: "";
  background-image: url(../image/youtube-flash.png);
  width: 220px;
  height: 160px;
  bottom: -59px;
    right: 0px;
    z-index: 1;
}

.yt-video::after {
  position: absolute;
  content: "";
  background-image: url(../image/yt-border-bottom.png);
  width: 100%;
  height: 2px;
  background-size: cover;
  background-repeat: no-repeat;
  bottom: 0;
  left: 0;
}

.yt-wrapper {
  position: relative;
}

.yt-wrapper::before, .faq-wrapper:before, .what-is-the-future:before{
  position: absolute;
  content: "";
  width: 38px;
  height: 36px;
  background: #D75FD2;
  opacity: 0.4;
  border-radius: 50%;
  filter: blur(16px);
  top: 55%;
  left: -100px;
}

.center {
  text-align: center;
}

.why-should-you-care {
  padding: 100px 0;

}
.what-is-the-future{
  position: relative;
}
.what-is-the-future:before{
  right: 0;
  left: unset;
  top: 70%;
}
.content-read-more {
  background-image: url(../image/trends-bg.webp);
  background-size: cover;
  background-repeat: no-repeat;
  border-top: 1px solid #414A60;
  border-bottom: 1px solid #414A60;
  position: relative;
}

.content-read-more:after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  background: url(../image/border-bottom-read-more.svg);
  background-repeat: no-repeat;
  width: 100%;
  height: 1px;
}
.content-grid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
}
.content-grid:before{
  content: url(../image/accent-botom-read-more.svg);
  position: absolute;
  left: -70px;
  bottom: -70px;
}
.software-trends {
  position: relative
}

.software-trends::after {
  position: absolute;
  content: "";
  background-image: url(../image/trends-middle.png);
  width: 2px;
  height: 360px;
  top: 0;
  right: 0;
}

.software-img {
  width: 100%;
  height: 95px;
  margin: 50px 0;
  background-image: url(../image/software-trends.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.hardware-img {
  width: 100%;
  height: 95px;
  margin: 50px 0;
  background-image: url(../image/hardware-trends.png);
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
}

.software-trends,
.hardware-trends {
  padding: 45px;
  position: relative;
}


/* Button */
.btn-box{
  padding: 60px 0;
  position: relative;
}
.btn-box:after{
  content: '';
  background: url(../image/button-bg.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 100%;
  background-position: center center;
  height: 200%;
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translateY(-50%) translateY(-50%);
  transform: translateX(-50%) translateY(-50%);
  z-index: 0;
}
.btn {
  background-color: #2C8CE4;
  transition: all 0.25s ease-in-out;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 700;
  font-size: 16px;
  line-height: 30px;
  text-align: center;
  color: #FFFFFF;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  width: 190px;
  height: 45px;
  margin-top: -25px;
  cursor: pointer;
  position: relative;
  z-index: 1;
}
.btn:hover{
  background-color: #3CB7FC;
}
.btn-box .btn{
  font-size: 20px;
  line-height: 30px;
  width: 240px;
  height: 56px;
  cursor: pointer;
  margin-top: -10px;
}
/* Read more */
  .m-hidden {
    display: none;
  }

  .m-visible {
    display: initial;
  }
  .m-slide-down {
    -moz-transition: height .5s;
    -ms-transition: height .5s;
    -o-transition: height .5s;
    -webkit-transition: height .5s;
    transition: height .5s;
    height: 0;
    overflow: hidden;
  }
.m-readmore-btn {
  text-decoration: underline;
  font-weight: 900;
  color: #2C8CE4;
  cursor: pointer;
}


@media only screen and (max-width: 768px) {
  .site-header-inner {
    padding: 26px 20px 28px;
    display: flex;
    flex-direction: column;
  }
  .header-nav, .header-content{
    padding: 0;
  }
  .header-content:after{
    content: '';
    background-image: url(../image/border-hero-mob.svg);
    width: 100%;
    height: 20px;
    bottom: 0;
    left: 0;
    top: unset;
    right: 0;
    margin: auto;
  }
  .nav{
    text-align: center;
  }
  .header-nav ul li a::before{
    right: 0;
    margin: 0 auto;
  }
  .header-description .m-slide-down{
    height: 0;
  }
  .header-description .m-readmore-btn-wrapper{
    display: block;
  }
  .container{
    padding: 0 20px;
  }
}

.header-image {
  position: absolute;
  right: 0;
  bottom: 0;
}
@media only screen and (max-width: 768px) {
  .header-image {
    display: none;
  }
}

@media only screen and (max-width: 768px) {
  .header-description {
    margin-bottom: 26px;
  }
}

.nav-wrapper p {
  margin-bottom: 54px;
  letter-spacing: 0.345em;
}
@media only screen and (max-width: 768px) {
  .nav-wrapper p {
    margin-bottom: 20px;
  }
}

.nav-wrapper ul {
  display: flex;
}
.nav-wrapper li {
  box-shadow: 0px 4px 33px 6px rgba(0, 0, 0, 0.1);
  width: 256px;
  height: 65px;
  border-radius: 50px;
  margin-right: 31px;
}
@media only screen and (max-width: 768px) {
  .nav-wrapper ul {
    display: block;
  }
  .nav-wrapper ul li:not(:last-child) {
    margin-bottom: 16px;
  }
  .nav-wrapper li {
    width: 100%;
  }
}

.nav-wrapper a {
  box-shadow: inset 0 0 0 0 #8dca65;
  -webkit-transition: ease-out 0.4s;
  -moz-transition: ease-out 0.4s;
  transition: ease-out 0.4s;
  display: block;
  font-size: 18px;
  font-weight: bold;
  font-style: normal;
  text-decoration: none;
  text-indent: 67px;
  line-height: 65px;
  border: 0;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 50px;
  margin-bottom: 58px;
  position: relative;
}

.nav-wrapper a:hover {
  opacity: 1;
  background-color: #8dca65;
  color: #fafafa;
  transition: all 0.2s linear;
  cursor: pointer;
  box-shadow: inset 0 0 0 50px #8dca65;
}

.nav-wrapper a:before {
  content: "";
  position: absolute;
  width: 32px;
  height: 32px;
  background-repeat: no-repeat;
  background-position: center;
  top: 16px;
  left: 20px;
}

footer{
 background-color: #1E2433;
 padding: 30px 0;
}

@media only screen and (max-width: 768px) {
  footer .container {
    flex-direction: column;
    padding-bottom: 24px;
  }
}

footer .copyright {
  color: #4C5772;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
}


@media only screen and (max-width: 768px) {
  header,
  article {
    padding: 0px 22px;
  }

  .mobile-detector {
    display: none;
  }

  .m-visible {
    display: initial;
  }

  .m-hidden {
    display: none;
  }

  footer .logo-wrapper {
    padding-bottom: 8px;
  }
}


.socials{
  margin: 50px 0;
}
.socials .content-read-more{
  padding: 30px;
}
.text-center{
  text-align: center;
}
.social-items{
  padding: 30px 80px;
}
.social-item .sub-heading{
  font-weight: 400;
  font-size: 22px;
  line-height: 60px;
  color: #FFFFFF;
  margin-left: 25px;
  position: relative;
}
.social-item .sub-heading:before {
  color: #2C8CE4;
  content: "\2022";
  display: inline-block;
  font-size: 26px;
  font-weight: 700;
  margin-left: -25px;
  position: absolute;
  width: 1em;
}
.line{
  display: block;
  height: 1px;
  background: #414A60;
  margin: 50px 0;

}

/* FAQ */

.faq-wrapper {
  display: flex;
  align-items: flex-start;
  gap: 40px;
  position: relative;
}
.faq-wrapper:after{
  left: -150px;
}
.faq-wrapper:before{
  top: 100%;
  left: -200px
}
.faq-wrapper .sub-title{
  background-image: url(../image/faq-bg.svg);
  background-size: cover;
  flex-basis: 50%;
  padding: 55px;
}
.faq-wrapper .sub-title h2{
  font-weight: 400;
  font-size: 26px;
  line-height: 60px;
}
.faq-listing {
  flex-basis: 50%;
}
.faq{
  border-top: 1px solid #414A60;
  position: relative;
  padding: 10px 0px 10px 0;
  transition: all 0.1s ease-in;
}

.faq:nth-child(1):after{
  content: url(../image/faq-line-accent.svg);
  position: absolute;
  top: 11px;
  left: -50px;
  z-index: 1;
}
.faq:last-of-type{
  border-bottom: 1px solid #414A60;;
}
.faq.expanded {
  border-bottom: 1px solid #414A60;;
}
.faq .faq-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.faq .faq-title h3 {
  pointer-events: none;
  font-weight: 400;
  font-size: 17px;
  line-height: 60px;
}

.faq.expanded .faq-title{
  padding-bottom: 20px;
}
.faq.expanded .faq-title h3 {
  margin-bottom: 0px;
}
.faq .faq-body a{
  text-decoration-line: underline;
  color: #2C8CE4;
}
.faq.collapsed .faq-body {
  opacity: 1;
  max-height: 100%;
}

.faq.collapsed .faq-body {
  opacity: 0;
  max-height: 0;
  overflow: hidden;
}

.faq-btn {
  background-image: url(../image/arrow-down.svg);
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  height: 15px;
  width: 15px;
  background-color: transparent;
  border: none;
  transition: transform 0.25s linear;
}

.faq-btn:hover {
  cursor: pointer;
}

.faq.expanded .faq-btn {
  transform: rotateZ(-180deg);
}

/* About */
.about-box{
  margin: 60px 0;
}

.scroll-to-link>*{
  pointer-events: none;
}

@media only screen and (max-width: 768px) {
  .grid-box, .grid1, .content-grid, .faq-wrapper{
    display: flex;
    flex-direction: column;
  }
  .how-it-works-grid{
    display: table;
    margin: 0 auto;
  }
  .grid1{
    padding: 0 0 40px;
  }
  .first-ul{
    padding: 0;
  }
  .yt-video{
    background-image: none;
    background-color: transparent;
    padding: 0;
    margin: 25px 0;
  }
  .yt-video iframe{
    max-height: 200px;
    width: 320px;
    max-width: 100%;
    margin: 0 auto;
    display: block;
  }
  .yt-video::before, .yt-video::after, .faq:nth-child(1):after, .why-care::before{
    content: none;
  }
  .social-items {
    padding: 0 20px;
    text-align: center;
  }
  .faq-wrapper .sub-title{
    text-align: center;
  }
  .faq-wrapper:after{
    top: 0;
    left: 0;
  }
  .faq-wrapper:before {
    top: 10%;
    left: 0;
  }
  .faq .faq-title h3{
    text-align: left;
    font-size: 17px;
    line-height: 25px;
    flex-basis: 75%;
  }
  .content-grid{
    gap: 60px;
  }
  .content-grid:before{
    content: none;
  }
  .what-is-the-future .content-read-more{
    background: none;
  }
  .software-trends, .hardware-trends{
    background-image: url(../image/trends-bg.webp);
    background-size: cover;
    background-repeat: no-repeat;
  }
  .hardware-trends{
    border-top: 1px solid #414A60;
    position: relative;
  }
  .software-trends:after {
      content: none;
  }
  .software-trends:before {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    background: url(../image/border-bottom-read-more.svg);
    background-repeat: no-repeat;
    width: 100%;
    height: 1px;
}
 .what-is-the-future  .m-visible.m-readmore-btn-wrapper{
    position: absolute;
    bottom: -65px;
    right: 0;
    left: 0;
  }

 .what-is-the-future .m-visible .m-readmore-btn:before{
    content: '';
    background-image: url(../image/accent-botom-read-more.svg);
    background-size: cover;
    background-position: center;
    position: absolute;
    background-repeat: no-repeat;
    width: 50%;
    height: 200%;
    left: 88%;
    right: 0;
    top: -33px;
  } 

  .what-is-the-future .m-visible .m-readmore-btn{
      background-color: #2C8CE4;
      transition: all 0.25s ease-in-out;
      font-family: 'Roboto';
      text-decoration: none;
      font-style: normal;
      font-weight: 700;
      font-size: 16px;
      line-height: 30px;
      text-align: center;
      color: #FFFFFF;
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0 auto;
      width: 190px;
      height: 45px;
      margin-top: -25px;
      cursor: pointer;
      position: relative;
      z-index: 1;
  }
  .what-is-the-future .m-visible .m-hidden{
    display: none;
  }
  .what-is-the-future .m-visible .m-readmore-btn:hover{
      background-color: #3CB7FC;
  }
}

@media only screen and (max-width: 368px) {
  .header-nav ul li a{
    font-size: 14px;
  }
  .header-nav ul li+li{
    margin-top: 30px;
  }
}

/* Mobile menu */

.site-nav-mob{
  display: none;
}
@media only screen and (max-width: 768px) {
  .site-nav-mob{
    display: block;
  }
  .site-nav-mob .icon-box {
    cursor: pointer;
    position: relative;
    height: 66px;
    display: block;
    margin: 0 auto;
    top: 0;
    width: 66px;
  }
  .site-nav-mob .nav-icon{
    border-top: 3px solid #2C8CE4;
    height: 24px;
    width: 28px;
    box-sizing: border-box;
    position: absolute;
    z-index: 30;
    right: 14px;
    top: 17px;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -khtml-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .site-nav-mob .nav-icon:before {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 28px;
    right: 0;
    background: #2C8CE4;
    top: 8px;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -khtml-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .site-nav-mob .nav-icon:after {
    content: "";
    display: block;
    position: absolute;
    height: 3px;
    width: 28px;
    right: 0;
    background: #2C8CE4;
    bottom: -1px;
    -webkit-transition: all 0.3s ease-in;
    -moz-transition: all 0.3s ease-in;
    -khtml-transition: all 0.3s ease-in;
    -o-transition: all 0.3s ease-in;
    transition: all 0.3s ease-in;
  }
  .nav-visible .site-nav-mob .icon-box{
    position: absolute;
    right: 0;
  }
  .nav-visible .site-nav-mob .nav-icon{
    border-top: none;
  }
  .nav-visible .site-nav-mob .nav-icon:before {
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -khtml-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    width: 33px;
    right: -2px;
  }
  .nav-visible .site-nav-mob .nav-icon:after {
    -webkit-transform: rotate(135deg);
    -moz-transform: rotate(135deg);
    -khtml-transform: rotate(135deg);
    -o-transform: rotate(135deg);
    transform: rotate(135deg);
    width: 33px;
    right: -1px;
    top: 8px;
  }
  .nav-visible .site-nav-mob{
    z-index: 999;
    background: #252C3C;
    display: grid;
    gap: 20px;
    justify-content: center;
    align-items: center;
    opacity: 1;
    visibility: visible;
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    overflow-x: hidden;
    overflow-y: auto;
    text-align: center;
    margin: 0 auto;
  }
  .site-nav-mob>ul {
    display: none;
  }
  .nav-visible .site-nav-mob>ul {
    opacity: 1;
    visibility: visible;
    display: block
    }
  .nav-visible .site-nav-mob>ul>li{
    padding: 20px;
    margin: 0 0 30px 0;
    transition: all 0.25s ease-in-out;
  }
  .nav-visible .site-nav-mob>ul>li:hover{
    background: rgba(20, 25, 36, 0.8);
    border-radius: 22px;
  }
  .nav-visible .site-nav-mob>ul>li:hover a{
    color: #2C8CE4;
  }

  .nav-visible .site-nav-mob>ul>li a{
    font-weight: 700;
    font-size: 23px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
  }
}